import {
  Avatar,
  colors,
  ImageList,
  ImageListItem,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";
import { colors as farmColor } from "../../common/colors";
import styled from "styled-components";
import { T } from "react-translator-component";
import profile from "../../assets/images/profile_img.png";
import CommentIcon from "@mui/icons-material/Comment";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
// import moment from "moment";
import { styles } from "../../pages/styles";
import React from "react";
import PostDescSeeMore from "../Phase2/PostDescSeeMore";
import Thumbnail from "../Phase2/Thumbnail";
import { faThumbsUp as thumbup } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useAppDispatch } from "../../redux/store";
// import CommentsModel from "../Models/CommentsModel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import VotesModel from "../Models/VotesModel";
import CommentsModel from "../Models/CommentsModel";
import moment from "moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 85%;
  overflow-y: hidden;
  z-index: 9999;
`;
interface Props {
  items: any[];
  buttonTitle?: string;
  onClickItem?(): void;
  page: string;
  order: string;
  status?: boolean;
  handleReport(post_id: number, status: any): void;
  onLike(post_id: number): void;
  onUnLike(post_id: number): void;
  likeLoading: boolean;
  showRanking?: boolean;
  showStatus?: boolean;
}
export default function VerticalListP2({
  items,
  onClickItem,
  showRanking = false,
  handleReport,
  likeLoading,
  onLike,
  onUnLike,
  showStatus = false,
}: Props) {
  // const navigate = useNavigate();
  const [showCommentsModal, setShowCommentsModal] = React.useState(0);
  const [showVotesModal, setShowVotesModal] = React.useState(0);
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.userAuth);
  const [reportConfirmation, setReportConfirmation] = React.useState(0);

  const todayDate = moment();

  // const like = async (item: any) => {
  //   setLikeLoading(true);
  //   try {
  //     await appServices.likeUnlike(item.id, "like");

  //     try {
  //       let data = { categoryId: "", page: page, order: order };
  //       await dispatch(getCategoryPosts(data));
  //       // await getSinglePost();
  //       setLikeLoading(false);
  //     } catch (error) {}
  //   } catch (error) {
  //     setLikeLoading(false);
  //   }
  //   // console.log('like')
  // };

  // const unlike = async (item: any) => {
  //   setLikeLoading(true);
  //   try {
  //     await appServices.likeUnlike(item.id, "delete_like");

  //     try {
  //       let data = { categoryId: "", page: page, order: order };
  //       await dispatch(getCategoryPosts(data));
  //       // await getSinglePost();
  //       setLikeLoading(false);
  //     } catch (error) {}
  //   } catch (error) {
  //     setLikeLoading(false);
  //   }
  //   console.log("unlike");
  // };

  const handleThumbUp = async (item: any) => {
    // setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        onUnLike(item);
      } else {
        onLike(item);
        // setLikeCount(likeCount + 1); // Increase the like count
      }
      // setThumbsUp(!thumbsUp);
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      // setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  // useEffect(() => {
  //   let el = document.getElementById("main");
  //   // console.log("Element:", el);
  //   // console.log("showCommentsModal:", showCommentsModal);
  //   if (showCommentsModal === 0 && el) {
  //     // el.style.display = "auto";
  //     // console.log("Element2:", el);
  //   } else if (el) {
  //     // el.style.display = "none";
  //     // console.log("Element3:", el);
  //   }
  // }, [showCommentsModal]);
  return (
    <div
      // id="main"
      style={{
        paddingTop: 20,
        paddingBottom: 50,
        borderBottomColor: colors.grey[400],
        borderBottomStyle: "solid",
        borderBottomWidth: 4,
        overflow: "hidden",
        display: "block",
      }}
    >
      <ImageList style={{ display: "block" }} cols={1}>
        {items?.map((item, index) => (
          <ImageListItem
            sx={{
              backgroundColor: farmColor.farm_gray10,
              borderRadius: 2,
            }}
            key={item.img}
          >
            <ListItem
              className=""
              alignItems="flex-start"
              style={{
                ...styles.detailListItem,
                borderBottomStyle: "solid",
                borderTopStyle: "solid",
                paddingLeft: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ListItemAvatar style={{ margin: 0, marginLeft: 8 }}>
                {item.is_anonymous === 3 ? (
                  <Avatar
                    style={{ margin: 0 }}
                    alt="Remy Sharp"
                    src={profile}
                  />
                ) : (
                  <Avatar
                    alt="Remy Sharp"
                    style={{ margin: 0 }}
                    src={
                      item?.user?.image !== null
                        ? "data:image/jpeg;base64," + item?.user?.image
                        : profile
                    }
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  item.is_anonymous === 1 ? (
                    <React.Fragment>
                      <Typography
                        sx={{ color: "#4792E0", fontSize: 20 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item?.user?.nickname}
                      </Typography>
                    </React.Fragment>
                  ) : item.is_anonymous === 2 ? (
                    <React.Fragment>
                      <Typography
                        sx={{ color: "#4792E0", fontSize: 20 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item?.user?.name
                          ? item?.user?.name
                          : item?.user?.nickname}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    "匿名"
                  )
                } //author
              />
              <>
                {item?.user?.id === user.id && (
                  <Button
                    style={{ fontSize: 10 }}
                    onClick={() => navigate("/post", { state: { post: item } })}
                    color={"secondary"}
                    variant="text"
                    startIcon={
                      <EditIcon style={{ marginRight: 0 }} fontSize="small" />
                    }
                  >
                    {T("Edit_Post")}
                  </Button>
                )}
                <>
                  {showStatus ? (
                    <Stack direction={"row"} sx={{ alignItems: "center" }}>
                      <Typography
                        style={{
                          color:
                            item.status === "Approved"
                              ? colors.green[500]
                              : item.status === "Rejected"
                              ? colors.red[500]
                              : item.status === "Reported"
                              ? colors.red[500]
                              : colors.yellow[800],
                        }}
                      >
                        {item.status === "Approved"
                          ? "掲載中"
                          : item.status === "Rejected"
                          ? "修正待ち"
                          : item.status === "Reported"
                          ? "報告"
                          : "審査中"}
                      </Typography>
                      <IconButton
                        sx={{}}
                        aria-label={`info about ${item.title}`}
                      >
                        {item.status === "Approved" && (
                          <CheckCircleOutlineIcon
                            style={{ color: colors.green[500], fontSize: 22 }}
                          />
                        )}
                        {item.status === "Pending" && (
                          <RemoveCircleOutlineIcon
                            style={{ color: colors.yellow[800], fontSize: 22 }}
                          />
                        )}
                        {(item.status === "Rejected" ||
                          item.status === "Reported") && (
                          <HighlightOffIcon
                            style={{ color: colors.red[500], fontSize: 22 }}
                          />
                        )}
                      </IconButton>
                    </Stack>
                  ) : (
                    user?.source === "admin" && (
                      <Button
                        onClick={() => setReportConfirmation(item.id)}
                        color={"warning"}
                        variant="text"
                        // startIcon={<EditIcon />}
                      >
                        {T("Report")}
                      </Button>
                    )
                  )}
                </>

                {showRanking && (
                  <IconButton
                    style={{ fontSize: 12, backgroundColor: "#B7E68D" }}
                  >
                    {index + 1}位
                  </IconButton>
                )}
              </>
              <Dialog
                open={reportConfirmation === item.id}
                onClose={() => setReportConfirmation(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {T("Report")} {/* //are you sure? */}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    この投稿を本当に報告しますか?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"secondary"}
                    onClick={() => setReportConfirmation(0)}
                  >
                    {T("No")}
                  </Button>
                  <Button
                    sx={{ fontFamily: "mplus-1c-bold" }}
                    color={"secondary"}
                    onClick={() => {
                      handleReport(item?.id, "report");
                    }}
                    autoFocus
                  >
                    {T("Yes")}
                  </Button>
                </DialogActions>
              </Dialog>
            </ListItem>
            <div>
              <Thumbnail deleteItem={() => {}} item={item} />
            </div>
            <div style={{ justifyContent: "center" }}>
              {likeLoading ? (
                <CircularProgress
                  style={{
                    marginTop: 10,
                    marginBottom: -8,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  size={24}
                />
              ) : (
                <IconButton
                  onClick={() => handleThumbUp(item)}
                  // onClick={() => handleVoteSection()}
                  disabled={user?.id === item?.user?.id}
                  // onClick={() => handleLike(state.id, "like")}
                  aria-label="thumbs"
                >
                  <FontAwesomeIcon
                    size={"lg"}
                    icon={item?.liked_by_me ? thumbup : faThumbsUp}
                    color={
                      item?.liked_by_me
                        ? farmColor.farm_green500
                        : farmColor.farm_gray200
                    }
                  />
                </IconButton>
              )}
              <span style={{ paddingTop: 6, fontSize: 16 }}>
                いいね！{item?.likes_count}件
              </span>
            </div>
            <Typography
              style={{
                marginLeft: 6,
                marginRight: 6,
                fontSize: 20,
                fontWeight: "600",
              }}
            >
              {item.title}
            </Typography>
            <PostDescSeeMore description={item.details} />

            <Stack direction="row" spacing={2}>
              {item?.enable_comments && (
                <Box>
                  <IconButton
                    onClick={() => setShowCommentsModal(item.id)}
                    // onClick={() => handleVoteSection()}

                    // onClick={() => handleLike(state.id, "like")}
                    aria-label="thumbs"
                  >
                    <CommentIcon />
                    <Typography>
                      コメント{item.comments_count} 件を読む
                    </Typography>
                  </IconButton>
                </Box>
              )}

              {/* vote_options?.length > 0 &&
                item?.enable_participants)  */}
              {item?.enable_participants && (
                <Box>
                  <IconButton
                    onClick={() => setShowVotesModal(item.id)}
                    // onClick={() => handleVoteSection()}

                    // onClick={() => handleLike(state.id, "like")}
                    aria-label="thumbs"
                  >
                    <HowToVoteIcon />
                    <Typography>投票する</Typography>
                  </IconButton>
                </Box>
              )}
            </Stack>

            {showCommentsModal === item.id && (
              <ModalBackground onClick={() => setShowCommentsModal(0)}>
                <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                  <CommentsModel
                    comments={item.comments}
                    post_id={item.id}
                    closeModal={() => setShowCommentsModal(0)}
                  />
                </ModalContainer>
              </ModalBackground>
            )}
            {showVotesModal === item.id && (
              <ModalBackground onClick={() => setShowVotesModal(0)}>
                <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                  <VotesModel
                    post={item}
                    closeModal={() => setShowVotesModal(0)}
                  />
                </ModalContainer>
              </ModalBackground>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
